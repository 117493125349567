<template>
    <div>
        <div v-for="(supplier,id) in inputVal.suppliers"
             :key="supplier.id"
        >
            <b-row>
                <b-col cols="8">
                    {{ supplier.is_company ? supplier.company : supplier.firstname + ' ' + supplier.lastname }} (ID:
                    {{ id }})
                </b-col>
                <b-col cols="4">
                    <zw-input-group v-model="inputVal.supplier_price[id]"
                                    name="supplier_price"
                                    type="number"
                                    disable-label
                                    @change="savePosition('supplier_price',inputVal)"
                                    size="sm"
                    ></zw-input-group>
                </b-col>

            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupplierPriceColumn',
    props: {
        value: [String, Number, Object],
        savePosition: [Function]
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>